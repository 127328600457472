<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Skupiny - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form">
        <a-form-item
          label="Název"
          :validate-status="error('grp_name') ? 'error' : ''"
          :help="error('grp_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['grp_name', {rules: [{max: 30, message: 'Název nemůže být delší než 30 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item label="Aktivní?">
          <a-checkbox v-decorator="['grp_is_active', { valuePropName: 'checked', initialValue: true, rules: [{required: true, message: 'Aktivní musí být vyplněno!'}] }]"/>
        </a-form-item>

        <a-form-item label="Je výchozí skupina?">
          <a-checkbox v-decorator="['grp_is_default', { valuePropName: 'checked', initialValue: false }]"/>
        </a-form-item>

        <div class="ant-col ant-form-item-label">
          <div title="Slevy pro jednotlivé kategorie" class="ant-form-item-required">Slevy pro jednotlivé kategorie:</div>
        </div>
        <a-tree
          class="tree-with-input"
          :treeData="treeData"
          :showIcon="true"
        >
          <div slot="custom" slot-scope="{title}">
            <a-input-number v-if="item.categories.items.length !== 0" class="w-100" placeholder="Sleva %" :min="0" :max="100" :step="0.01" :precision="2" v-model="item.categories.items.find(x => x.cey_id === Number.parseInt(title.substr(1))).gct_discount" :defaultValue="0" />
          </div>
        </a-tree>
        <div class="form-actions">
          <a-modal
            :visible="discountModalVisible"
            title="Potvrzení formuláře"
            cancel-text="Zrušit"
          >
            <a-form-item label="Zvolte způsob aktualizace slev">
              <a-radio-group v-model="discountOption">
                <a-radio :style="radioStyle" :value="2">
                  Přepsat slevu jen u produktů, které mají danou kategorii jako hlavní
                </a-radio>
                <a-radio :style="radioStyle" :value="3">
                  Nepřepisovat slevu u produktů
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <template #footer>
              <a-button key="back" @click="hideDiscountModal">Zrušit</a-button>
              <a-button key="submit" type="primary" @click="handleSubmit($event)" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
            </template>
          </a-modal>
          <a-button type="primary" @click="showDiscountModal" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'

export default {
  components: { ActionTools },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data: function () {
    return {
      hasErrors,
      dependencies: ['categories'],
      id: this.$route.params.id,
      loading: false,
      treeData: [],
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/group',
          title: 'Seznam',
        },
      ],
      discountModalVisible: false,
      discountOption: 1,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      item: {
        categories: {
          loading: false,
          items: [],
        },
      },
    }
  },
  computed: {
    categories: function() {
      return this.$store.getters['category/currentLanguage']
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    detail: function() {
      return this.$store.getters['group/getDetail']
    },
  },
  methods: {
    showDiscountModal() {
      this.discountModalVisible = true
    },
    hideDiscountModal() {
      this.discountModalVisible = false
    },
    setDefaultCategories(data) {
      data.forEach(value => {
        let discount = 0
        if (this.detail.category_discounts && this.detail.category_discounts.length !== 0) {
          const findItem = this.detail.category_discounts.find(x => x.cey_id === value.cey_id)
          discount = findItem && findItem.pivot ? findItem.pivot.gct_discount : 0
        }
        this.item.categories.items.push({
          cey_id: value.cey_id,
          gct_discount: discount,
          cle_name: value.languages[0].pivot.cle_name,
        })
        if (value.all_children.length > 0) {
          this.setDefaultCategories(value.all_children)
        }
      })
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    initData() {
      this.dependencies.forEach((value) => {
        this.item[value].loading = true
      })
      Promise.all([
        this.$store.dispatch('category/getList'),
      ])
        .then(() => {
          this.initDetail()
        })
        .catch(() => {})
        .finally(() => {
          this.dependencies.forEach((value) => {
            this.item[value].loading = false
          })
        })
    },
    initDetail() {
      this.$store.dispatch('group/getOne', this.id)
        .then(() => {
          this.item.categories.items = []
          this.setDefaultCategories(this.categories)
          this.form.setFieldsValue({
            grp_name: this.detail.grp_name,
            grp_is_default: this.detail.grp_is_default,
            grp_is_active: this.detail.grp_is_active,
          })
          this.form.validateFields()
        })
        .catch(() => {})
    },
    getData() {
      return {
        grp_is_default: this.form.getFieldValue('grp_is_default') === undefined ? false : this.form.getFieldValue('grp_is_default'),
        grp_is_active: this.form.getFieldValue('grp_is_active'),
        grp_name: this.form.getFieldValue('grp_name'),
        category_discounts: this.item.categories.items,
        option: this.discountOption,
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('group/put', { id: this.id, item: this.getData() })
            .then((response) => {
              this.$notification.success({
                message: response.message,
                description: response.description,
              })
            })
            .catch(() => {})
            .finally(() => {
              this.hideDiscountModal()
              this.loading = false
            })
        }
      })
    },
    toTreeData(data) {
      return data.map(x => {
        return {
          key: x.cey_id,
          title: '#' + x.cey_id + ' | ' + x.languages[0].pivot.cle_name,
          children: this.toTreeData(x.all_children),
          selectable: false,
          scopedSlots: { icon: 'custom', key: x.cey_id },
        }
      })
    },
    refreshComponent(id) {
      this.id = id
      this.item = {
        categories: {
          loading: false,
          items: [],
        },
      }
    },
  },
  created() {
    if (this.language !== null) {
      this.initData()
    }
  },
  watch: {
    language() {
      if (this.language !== null) {
        this.initData()
      }
    },
    categories(newValue, oldValue) {
      if (newValue !== null && oldValue !== newValue && newValue.length > 0) {
        this.treeData = this.toTreeData(this.categories)
        if (Object.keys(this.detail).length > 0) {
          this.item.categories.items = []
          this.setDefaultCategories(this.categories)
          this.form.validateFields()
        }
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initDetail()
      }
    },
  },
}
</script>
